import React from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import { Paper } from '@material-ui/core';
import { useAppContextData } from './AppContext';
import { groupBy } from './useGroupBy';
import ProductCategory from './ProductCategory';
import useFilterByTags from './useFilterByTags';

interface RoomProps {
  room: PresetRoom;
  parent: string;
}

const invalid = ['invalid'];

const Room: React.FC<RoomProps> = ({ room, parent }) => {
  const { roomSlots, roomCatsById, products } = useAppContextData();

  // const roomCategory = roomCatsById?.get(roomSlots[0].roomCatId || '');

  const roomCategory = roomCatsById?.get(room.roomCatId);

  const roomProducts = useFilterByTags(
    products || [],
    roomCategory?.tagsInclude || invalid,
    roomCategory?.tagsExclude || invalid,
    false
  );

  if (!roomSlots || !roomCategory) {
    return null;
  }

  const filtered = roomSlots.filter((rs) => rs.roomCatId === room.roomCatId);
  const byCategory = groupBy<RoomSlot>((s) => s.slot?.type || s.id, filtered);

  const productCategories = Array.from(
    byCategory.entries()
  ).map(([category, catSlots]) => (
    <ProductCategory
      key={category}
      roomSlots={catSlots}
      category={category}
      nodeId={room.id + room.roomCatId + category}
      parent={parent + category}
    />
  ));

  const label = `${room.roomName} (${productCategories.length})`;
  return (
    <TreeItem nodeId={parent + room.id} label={label}>
      <Paper>
        Room Product Count {roomProducts?.length}
        <br />
        Room Tags {roomCategory.tagsInclude.join(',')}
        {productCategories}
      </Paper>
    </TreeItem>
  );
};

export default Room;
