import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

interface MissingProductProps {
  product: Product;
}

const MissingProduct: React.FC<MissingProductProps> = ({ product }) => {
  return (
    <ListItem key={product.productCode}>
      <ListItemText
        primary={`${product.productCode} - ${product.name}`}
        secondary={product.tags.join(', ')}
      />
    </ListItem>
  );
};

export default MissingProduct;
