import React from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

interface YesNoProps {
  condition: boolean;
  yes: string;
  no: string;
  title: string;
}

const YesNo: React.FC<YesNoProps> = ({ condition, yes, no, title }) => {
  let body = <Typography variant="subtitle2">{no}</Typography>;
  if (condition) {
    body = <Typography variant="subtitle1">{yes}</Typography>;
  }

  return (
    <Box mt={2}>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <Box pl={1}>
            {condition && <CheckCircleIcon style={{ fill: '#087f23' }} />}
            {!condition && <ErrorIcon style={{ fill: '#ba000d' }} />}
          </Box>
        </Grid>
      </Grid>

      {body}
    </Box>
  );
};

export default YesNo;
