import useFilterByTags from '../useFilterByTags';
import { useAppContextData } from '../AppContext';

const empty = [];

export default function useProductsForRoomId(
  products: Product[] | undefined,
  roomId?: string
) {
  const { roomCatsById } = useAppContextData();
  const cat = roomCatsById?.get(roomId || 'invalid');
  return useFilterByTags(
    products || empty,
    cat?.tagsInclude || empty,
    cat?.tagsExclude || empty,
    true // Normally this is false, trying to match all products
  );
}
