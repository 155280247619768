import { useMemo } from 'react';

interface Tagged {
  tags: string[];
}

export function shouldFilter<T extends Tagged>(
  i: T,
  tagsInclude: string[],
  tagsExclude: string[],
  mustIncludeAll: boolean
): boolean {
  const tagSet = new Set(i.tags);
  // Exclude if the tag exists in tags exclude
  if (tagsExclude.find((t) => tagSet.has(t))) {
    return false;
  }

  if (mustIncludeAll) {
    // Exclude if any tag is missing
    const matchCount = tagsInclude.filter((t) => tagSet.has(t)).length;
    if (matchCount !== tagsInclude.length) {
      return false;
    }
    return true;
  }
  // Exclude if a tag is missing in include
  if (!tagsInclude.find((t) => tagSet.has(t))) {
    return false;
  }
  return true;
}

export function filterByTags<T extends Tagged>(
  items: T[],
  tagsInclude: string[],
  tagsExclude: string[],
  mustIncludeAll: boolean
): T[] | undefined {
  if (!items) {
    return undefined;
  }
  return items.filter((i) =>
    shouldFilter(i, tagsInclude, tagsExclude, mustIncludeAll)
  );
}

export function useFilterByTagsIterator<T extends Tagged>(
  items: IterableIterator<T>,
  tagsInclude: string[],
  tagsExclude: string[],
  mustIncludeAll: boolean
): T[] | undefined {
  return useMemo(() => {
    const filtered = filterByTags(
      Array.from(items),
      tagsInclude,
      tagsExclude,
      mustIncludeAll
    );
    return filtered;
  }, [items, tagsInclude, tagsExclude, mustIncludeAll]);
}

export default function useFilterByTags<T extends Tagged>(
  items: T[],
  tagsInclude: string[],
  tagsExclude: string[],
  mustIncludeAll: boolean
): T[] | undefined {
  return useMemo(() => {
    return filterByTags(items, tagsInclude, tagsExclude, mustIncludeAll);
  }, [items, tagsInclude, tagsExclude, mustIncludeAll]);
}
