import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useAppContextData } from '../AppContext';

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`;
interface RoomListItemProps {
  room: RoomCategory;
}

const RoomListItem: React.FC<RoomListItemProps> = ({ room }) => {
  const { roomSlots } = useAppContextData();
  const slots = roomSlots?.filter((rs) => rs.roomCatId === room.id);

  return (
    <StyledLink key={room.id} to={`/room/${room.id}`}>
      <ListItem button>
        <ListItemText
          primary={room.name}
          secondary={`Slot Count: ${slots?.length}`}
        />
      </ListItem>
    </StyledLink>
  );
};

const RoomListing: React.FC = () => {
  const { roomCatsById } = useAppContextData();
  if (!roomCatsById) {
    return null;
  }
  const rooms = Array.from(roomCatsById.values()).map((room) => (
    <RoomListItem room={room} key={room.id} />
  ));

  return (
    <>
      <Typography variant="h4">Room Listing</Typography>
      <List dense>{rooms}</List>
    </>
  );
};

export default RoomListing;
