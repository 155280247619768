import React, { useState, useCallback, useEffect, useContext } from 'react';
import { groupBy } from './useGroupBy';
import { mapBy } from './useMapBy';

interface ApplicationContext {
  loading: boolean;
  grouped?: Map<string, PresetRoom[]>;
  presetRooms?: PresetRoom[];
  presets?: Preset[];
  slotsById?: Map<string, Slot>;
  roomSlots?: RoomSlot[];
  products?: Product[];
  currentSheet: string;
  setCurrentSheet: (sheet: string) => void;
  roomCatsById?: Map<string, RoomCategory>;
}

const Context = React.createContext<ApplicationContext>({
  loading: false,
  currentSheet: '',
  setCurrentSheet: () => {
    // Do nothing
  },
});

type ValueRange = gapi.client.sheets.ValueRange;

function useSheetRangeData(sheetId: string, ranges: string[]) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ValueRange[]>([]);
  const fetch = useCallback(async () => {
    setLoading(true);
    setData([]);
    const response = await window.gapi.client.sheets.spreadsheets.values.batchGet(
      {
        spreadsheetId: sheetId,
        ranges,
      }
    );
    setLoading(false);
    setData(response.result.valueRanges || []);
    return response.result.valueRanges;
    // Disabled in case you are using literal arrays, meaning each render is a new object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    fetch,
    loading,
    data,
    sheetId,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function indexForRow(header: any[], name: string) {
  return header.findIndex((c) => c === name);
}

function toSlot(range: ValueRange): Slot[] {
  if (range && range.values && range.values.length > 2) {
    const header = range.values.slice(0, 1)[0];
    const nameIdx = indexForRow(header, '_name');
    const typeIdx = indexForRow(header, '_type');
    const tagsIncludeIdx = indexForRow(header, '_tags_include');
    const tagsExcludeIdx = indexForRow(header, '_tags_exclude');
    return range.values.slice(1).map((row) => {
      return {
        tagsInclude: toTags(row[tagsIncludeIdx] as string),
        tagsExclude: toTags(row[tagsExcludeIdx] as string),
        name: row[nameIdx] as string,
        type: row[typeIdx] as string,
        id: row[0] as string,
      };
    });
  }
  return [];
}

function toRoomSlot(
  range: ValueRange,
  slotsById: Map<string, Slot>
): RoomSlot[] {
  if (range && range.values && range.values.length > 2) {
    const header = range.values.slice(0, 1)[0];
    const slotIdIdx = indexForRow(header, '_slot_id');
    const roomIdIdx = indexForRow(header, '_room_category_id');
    return range.values.slice(1).map((row) => {
      const slotId = row[slotIdIdx] as string;
      return {
        id: row[0] as string,
        slot: slotsById.get(slotId),
        roomCatId: row[roomIdIdx] as string,
      };
    });
  }
  return [];
}

function toPreset(range: ValueRange): Preset[] {
  if (range && range.values) {
    const header = range.values.slice(1, 2)[0];
    const nameIdx = indexForRow(header, '_name');
    const idIdx = indexForRow(header, '_id');
    const uuidIdx = indexForRow(header, '_uuid');
    const parentIdx = indexForRow(header, '_parent_id');
    const showIdx = indexForRow(header, '_show_ui');
    const results = range.values.slice(2).map(
      (row): Preset => {
        return {
          uuid: row[uuidIdx],
          id: row[idIdx],
          name: row[nameIdx],
          parentId: row[parentIdx],
          show: row[showIdx] === 'TRUE',
        };
      }
    );
    results.forEach((p) => {
      if (p.parentId) {
        // eslint-disable-next-line no-param-reassign
        p.parent = results.find((parent) => parent.id === p.parentId);
      }
    });
    return results;
  }
  return [];
}

function toPresetDetail(range: ValueRange): PresetRoom[] {
  if (range && range.values) {
    const header = range.values.slice(0, 1)[0];
    const presetIdx = indexForRow(header, 'Preset');
    const nameIndex = indexForRow(header, '_room_name');
    const roomIdIdx = indexForRow(header, '_room_category_id');
    const presetIdIdx = indexForRow(header, '_preset_id');
    return range.values.slice(1).map((row) => {
      return {
        id: row[0],
        roomCatId: row[roomIdIdx] as string,
        roomName: row[nameIndex] as string,
        slots: [],
        preset: row[presetIdx] as string,
        presetId: row[presetIdIdx] as string,
      };
    });
  }
  return [];
}

function toTags(s?: string): string[] {
  if (s && s.length > 2) {
    return s
      .trim()
      .substr(1, s.length - 2)
      .split(',')
      .map((t) => t.trim());
  }
  return [];
}

function toProduct(range: ValueRange): Product[] {
  if (range && range.values) {
    const header = range.values.slice(8, 9)[0];
    const uuidIdx = indexForRow(header, '_uuid');
    const nameIdx = indexForRow(header, '_primary_name');
    const tagIdx = indexForRow(header, '_tags');
    const imgPrimaryIdx = indexForRow(header, '_image_primary');
    const imgSecondaryIdx = indexForRow(header, '_image_secondary');
    const productCodeIdx = indexForRow(header, '_franchise_product_code');
    const presetIdx = indexForRow(header, '_preset');
    const renderSlotIdx = indexForRow(header, '_render_slot');
    const renderModelIdx = indexForRow(header, '_render_model');
    const skuIdx = indexForRow(header, '_sku');

    return range.values.slice(9).map((row) => {
      return {
        id: row[uuidIdx] as string,
        name: row[nameIdx] as string,
        imagePrimary: row[imgPrimaryIdx] as string,
        imageSecondary: row[imgSecondaryIdx] as string,
        tags: toTags(row[tagIdx] as string),
        productCode: row[productCodeIdx] as string,
        preset: row[presetIdx] as string,
        renderSlot: row[renderSlotIdx] as string,
        renderModel: row[renderModelIdx] as string,
        sku: row[skuIdx] as string,
      };
    });
  }
  return [];
}

function toRoomCategory(range: ValueRange): RoomCategory[] {
  if (range && range.values) {
    const header = range.values.slice(0, 1)[0];
    const uuidIdx = indexForRow(header, '_uuid');
    const tagsIncludeIdx = indexForRow(header, '_tags_include');
    const tagsExcludeIdx = indexForRow(header, '_tags_exclude');
    const nameIdx = indexForRow(header, '_name');
    return range.values.slice(1).map((row) => {
      return {
        id: row[uuidIdx] as string,
        name: row[nameIdx] as string,
        tagsInclude: toTags(row[tagsIncludeIdx] as string),
        tagsExclude: toTags(row[tagsExcludeIdx] as string),
      };
    });
  }
  return [];
}
const SHEET_ID = '1oGvOovDQ09_Docn01PU0IE243Ss3uVxGz9YF3jjEvLk';
//const SHEET_ID = '1oGvOovDQ09_Docn01PU0IE243Ss3uVxGz9YF3jjEvLk'; // Development

function useContextData() {
  const [currentSheet, setCurrentSheet] = useState(SHEET_ID);

  const { fetch, data, loading } = useSheetRangeData(currentSheet, [
    'preset_room_detail!A1:Z1000',
    'slot!A1:Z1000',
    'room_slots!A1:Z1000',
    'product!A1:AZ5000',
    'room_category!A1:Z1000',
    'preset!A1:Z1000',
  ]);
  useEffect(() => {
    fetch();
  }, [fetch, currentSheet]);
  const result: ApplicationContext = {
    loading,
    currentSheet,
    setCurrentSheet,
  };
  if (data && data.length > 0 && data[0].values) {
    result.presetRooms = toPresetDetail(data[0]);
    result.slotsById = mapBy<Slot>('id', toSlot(data[1])) as Map<string, Slot>;
    result.roomSlots = toRoomSlot(data[2], result.slotsById);
    result.products = toProduct(data[3]);
    result.roomCatsById = mapBy<RoomCategory>(
      'id',
      toRoomCategory(data[4])
    ) as Map<string, RoomCategory>;
    result.presets = toPreset(data[5]);
    result.grouped = groupBy<PresetRoom>('preset', result.presetRooms);
  }
  return result;
}

export function useAppContextData() {
  return useContext<ApplicationContext>(Context);
}

const AppContext: React.FC = ({ children }) => {
  const contextData = useContextData();
  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export default AppContext;
