import React from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import Slot from './Slot';
import { useAppContextData } from './AppContext';
import useFilterByTags from './useFilterByTags';

interface ProductCategoryProps {
  roomSlots: RoomSlot[];
  nodeId: string;
  category: string;
  parent: string;
}

const ProductCategory: React.FC<ProductCategoryProps> = ({
  nodeId,
  roomSlots,
  category,
  parent,
}) => {
  const { roomCatsById, products } = useAppContextData();
  const roomCategory = roomCatsById?.get(roomSlots[0].roomCatId);
  const roomProducts = useFilterByTags(
    products || [],
    roomCategory?.tagsInclude || ['invalid'],
    roomCategory?.tagsExclude || [],
    false
  );
  const slots = roomSlots.map((rs) => (
    <Slot
      key={rs.id}
      slot={rs.slot}
      parent={parent + category}
      roomProducts={roomProducts || []}
    />
  ));
  const label = `${category} (${slots.length})`;
  return (
    <TreeItem nodeId={nodeId} label={label}>
      {slots}
    </TreeItem>
  );
};

export default ProductCategory;
