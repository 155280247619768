import styled from '@emotion/styled';
import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppContextData } from '../AppContext';

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`;

interface PresetRoomItemProps {
  presetRoom: PresetRoom;
}

const PresetRoomItem: React.FC<PresetRoomItemProps> = ({ presetRoom }) => {
  return (
    <StyledLink to={`/presetroom/${presetRoom.id}/`}>
      <ListItem button>
        <ListItemText primary={presetRoom.roomName} />
      </ListItem>
    </StyledLink>
  );
};

const PresetDetail: React.FC = () => {
  const { presetId } = useParams<{ presetId: string }>();
  const { presetRooms: presetRoomsAll } = useAppContextData();

  if (!presetRoomsAll) {
    return null;
  }

  const presetRooms = presetRoomsAll?.filter((p) => p.presetId === presetId);

  if (presetRooms.length === 0) {
    return null;
  }
  const presetName = presetRooms[1].preset;
  const rooms = presetRooms.map((pr) => (
    <PresetRoomItem key={pr.id} presetRoom={pr} />
  ));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box pb={4}>
          <Typography variant="h4">{presetName}</Typography>
          {rooms}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PresetDetail;
