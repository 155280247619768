import React from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import Room from './Room';

interface PresetProps {
  rooms: PresetRoom[];
  title: string;
}

const Preset: React.FC<PresetProps> = ({ rooms, title }) => {
  const items = rooms.map((r) => (
    <Room key={r.preset + r.roomName} room={r} parent={r.preset + r.roomName} />
  ));
  const label = `${title} (${items.length})`;
  return (
    <TreeItem nodeId={title} label={label}>
      {items}
    </TreeItem>
  );
};

export default Preset;
