import React, { useCallback, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Box, Typography, Card, Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import { spacing, SpacingProps } from '@material-ui/system';

const LoginCard = styled(Card)<SpacingProps>`
  text-align: center;
  ${spacing}
`;

function useGoogleAuth(onSignIn) {
  const [signedIn, setSignedIn] = useState(false);
  const check = useCallback(async () => {
    window.gapi.auth2.getAuthInstance().isSignedIn.listen(() => {
      setSignedIn(true);
      onSignIn(true);
    });
    if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      setSignedIn(true);
      onSignIn(true);
    }
  }, [setSignedIn, onSignIn]);

  useEffect(() => {
    check();
  }, [check]);
  return {
    signedIn,
  };
}

interface SignInProps {
  onSignIn: (signedIn: boolean) => void;
}

const SignIn: React.FC<SignInProps> = ({ onSignIn }) => {
  const { signedIn } = useGoogleAuth(onSignIn);
  if (signedIn) {
    return null;
  }
  return (
    <Grid
      container
      justify="center"
      spacing={0}
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <LoginCard mt={6}>
          <Box p={4}>
            <Typography variant="h5">Sign in to continue</Typography>
            <Box p={3}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => window.gapi.auth2.getAuthInstance().signIn()}
              >
                Sign in
              </Button>
            </Box>
          </Box>
        </LoginCard>
      </Grid>
    </Grid>
  );
};

export default SignIn;
