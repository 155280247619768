import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`;

const Menu: React.FC = () => {
  return (
    <>
      <Typography variant="h4">HF Product Admin</Typography>
      <List>
        <StyledLink to="/preset">
          <ListItem button>
            <ListItemText primary="Presets" />
          </ListItem>
        </StyledLink>
        <StyledLink to="/products">
          <ListItem button>
            <ListItemText primary="Products" />
          </ListItem>
        </StyledLink>
      </List>
    </>
  );
};

export default Menu;
