import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { shouldFilter } from '../useFilterByTags';
import YesNo from './YesNo';

interface ProductDebugProps {
  product?: Product;
  room?: RoomCategory;
  slot?: RoomSlot;
}

const ProductDebug: React.FC<ProductDebugProps> = ({ product, room, slot }) => {
  if (!room || !product || !slot || !slot.slot) {
    return null;
  }

  const isInRoom = shouldFilter(
    product,
    room.tagsInclude || [],
    room.tagsExclude || [],
    true // Switched from false
  );
  const isInSlot = shouldFilter(
    product,
    slot.slot?.tagsInclude || [],
    slot.slot?.tagsExclude || [],
    true
  );

  const tagSet = new Set(product.tags);

  const roomExcludedTags = room.tagsExclude.filter((t) => tagSet.has(t));
  const roomMissingTags = room.tagsInclude.filter((t) => !tagSet.has(t));

  const slotMissingTags = slot.slot.tagsInclude.filter((t) => !tagSet.has(t));
  const slotExcludeTags = slot.slot.tagsExclude.filter((t) => tagSet.has(t));

  return (
    <div>
      <Typography variant="h5">{product.name}</Typography>
      <Typography variant="subtitle2">
        Product Tags: {product.tags.join(', ')}
      </Typography>
      <Box>
        <YesNo
          condition={roomMissingTags.length === 0}
          title="Is the product included in the room?"
          yes="Yes, the product has all matching room tags"
          no={`No, the product does not match the room tags, it's missing ${roomMissingTags.join(
            ', '
          )}`}
        />
        <YesNo
          title="Has it been excluded?"
          condition={roomExcludedTags.length === 0}
          yes="No exclusions for this product"
          no={`This product has been excluded from the room. Excluded Tags: ${roomExcludedTags.join(
            ', '
          )}`}
        />
        <YesNo
          title="So can this product be used in the room?"
          condition={isInRoom}
          yes="Sure"
          no="Nope"
        />
        <YesNo
          title="Does the product have all slot tags?"
          condition={slotMissingTags.length === 0}
          yes="Yes, it has all required tags"
          no={`No, it's missing ${slotMissingTags.join(', ')}`}
        />
        <YesNo
          title="Has the product been excluded by the slot?"
          condition={slotExcludeTags.length === 0}
          yes="No, it has not been excluded"
          no={`Yes, it has been excluded with the tags ${slotExcludeTags.join(
            ', '
          )}`}
        />
        <YesNo
          title="So can this product be used in the slot?"
          condition={isInSlot && isInRoom}
          yes="Yup!"
          no="Nope"
        />
      </Box>
    </div>
  );
};

export default ProductDebug;
