import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useAppContextData } from '../AppContext';

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`;

interface PresetListItemProps {
  preset: Preset;
}

const PresetListItem: React.FC<PresetListItemProps> = ({ preset }) => {
  return (
    <StyledLink key={preset.id} to={`/preset/${preset.uuid}`}>
      <ListItem button>
        <ListItemText primary={preset.name} />
      </ListItem>
    </StyledLink>
  );
};

const PresetListing: React.FC = () => {
  const { presets } = useAppContextData();
  if (!presets) {
    return null;
  }
  const presetListing = presets
    .filter((p) => p.show)
    .map((p) => <PresetListItem preset={p} key={p.uuid} />);
  return (
    <>
      <Typography variant="h4">Preset Listing</Typography>
      <List dense>{presetListing}</List>
    </>
  );
};

export default PresetListing;
