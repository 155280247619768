import React from 'react';
import TreeItem from '@material-ui/lab/TreeItem';

import { List } from '@material-ui/core';
import Product from './Product';
import useFilterByTags from './useFilterByTags';

interface SlotProps {
  slot?: Slot;
  parent: string;
  roomProducts: Product[];
}

const invalid = ['invalid'];

const Slot: React.FC<SlotProps> = ({ slot, parent, roomProducts }) => {
  const filtered = useFilterByTags(
    roomProducts,
    slot?.tagsInclude || invalid,
    slot?.tagsExclude || invalid,
    true
  );

  if (!slot) {
    return null;
  }
  const products = (filtered || []).map((p) => (
    <Product product={p} key={p.id} />
  ));
  const label = `${slot.name} (${filtered?.length})`;
  return (
    <TreeItem nodeId={parent + (slot.id || '')} label={label}>
      <List>{products}</List>
    </TreeItem>
  );
};

export default Slot;
