/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAppContextData } from '../AppContext';
import { StyledCard } from './styled';
import ProductDebug from './ProductDebug';
import { useProductsForPresets } from './useProductsForPresets';
import useFilterByTags from '../useFilterByTags';
import SlotProducts from './SlotProducts';
import useProductsForRoomId from './useProductsForRoomId';

const empty = [];

const SlotInspector: React.FC = () => {
  const [currentProduct, setProduct] = useState<Product | null>(null);
  const { presetRoomId, slotId } = useParams<{
    slotId: string;
    presetRoomId: string;
  }>();

  const { roomCatsById, roomSlots, presetRooms } = useAppContextData();
  const presetRoom = (presetRooms || empty).find((p) => p.id === presetRoomId);
  const presetProducts = useProductsForPresets(presetRoom);
  const roomProducts = useProductsForRoomId(
    presetProducts,
    presetRoom?.roomCatId || ''
  );

  const slot = (roomSlots || empty).find((s) => s.slot?.id === slotId);

  const slotProducts = useFilterByTags(
    roomProducts || empty,
    slot?.slot?.tagsInclude || empty,
    slot?.slot?.tagsExclude || empty,
    true
  );
  if (!roomCatsById || !roomSlots || !presetRooms) {
    return null;
  }

  if (!presetRoom) {
    return null;
  }

  const roomCategory = roomCatsById.get(presetRoom.roomCatId);

  if (!slot) {
    return <Typography variant="h5">Unable to load slot</Typography>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h4">Slot Inspector</Typography>
          <Typography variant="h5">
            {presetRoom?.preset} - {presetRoom?.roomName} - {slot.slot?.name}
          </Typography>
          <Box pt={2}>
            <Typography variant="subtitle2">
              Room Tags: {roomCategory?.tagsInclude.join(', ')}
            </Typography>
            <Typography variant="subtitle2">
              Excluded Room Tags: {roomCategory?.tagsExclude.join(', ')}
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography variant="subtitle2">
              Slot Tags: {slot.slot?.tagsInclude.join(', ')}
            </Typography>
            <Typography variant="subtitle2">
              Excluded Slot Tags: {slot.slot?.tagsExclude.join(', ')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pt={2}>
          <SlotProducts products={slotProducts} onSelectProduct={(p)=> setProduct(p)}/>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <StyledCard p={3}>
          <Typography variant="subtitle2">
            Select a product to see why (or why it can&apos;t) be used in this
            room
          </Typography>
          <Box mt={2}>
            <Autocomplete
              options={presetProducts}
              getOptionLabel={(p) => `${p.productCode} - ${p.name}`}
              style={{ width: 400 }}
              onChange={(_, o) => setProduct(o)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Product"
                  variant="outlined"
                />
              )}
            />
          </Box>
        </StyledCard>
        {currentProduct && (
          <StyledCard p={3} mt={2}>
            <ProductDebug
              product={currentProduct || undefined}
              slot={slot}
              room={roomCategory}
            />
          </StyledCard>
        )}
      </Grid>
    </Grid>
  );
};

export default SlotInspector;
