import { useMemo } from 'react';

export function mapBy<T>(key: keyof T, items: Array<T> | undefined | null) {
  const result = new Map<T[keyof T], T>();
  if (items) {
    items.forEach((i) => {
      const a = i[key];
      if (a) {
        result.set(a, i);
      }
    });
  }
  return result;
}

export default function useMapBy<T>(
  key: keyof T,
  items: Array<T> | undefined | null
) {
  return useMemo(() => mapBy(key, items), [items, key]);
}
