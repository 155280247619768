import styled from '@emotion/styled';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';

const StyledImg = styled.img`
  display: block;
  max-width: 80px;
  max-height: 80px;
  width: auto;
  height: auto;
`;

interface SlotProductsProps {
  products?: Product[];
  onSelectProduct?: (p: Product | null) => void;
}

const SlotProducts: React.FC<SlotProductsProps> = ({
  products,
  onSelectProduct = () => undefined,
}) => {
  if (!products) {
    return <div>No Products for slot</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Primary Image</TableCell>
            <TableCell />
            <TableCell>Render Slot</TableCell>
            <TableCell>Render Model</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.sku}</TableCell>
              <TableCell>{product.productCode}</TableCell>
              <TableCell>
                {product.imagePrimary && (
                  <StyledImg
                    src={`https://assets.hotelfurniture.com/products/original/${product.imagePrimary}`}
                    alt="Primary"
                  />
                )}
              </TableCell>
              <TableCell>
                {product.imageSecondary && (
                  <StyledImg
                    src={`https://assets.hotelfurniture.com/products/original/${product.imageSecondary}`}
                    alt="Secondary"
                  />
                )}
              </TableCell>
              <TableCell>{product.renderSlot}</TableCell>
              <TableCell>{product.renderModel}</TableCell>
              <TableCell>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onSelectProduct(product)}
                >
                  <InfoIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SlotProducts;
