import { useAppContextData } from '../AppContext';

export function useProductsForPresets(presetRoom?: PresetRoom): Product[] {
  const { presets, products } = useAppContextData();
  if (!presetRoom) {
    return products || [];
  }

  const preset = (presets || []).find((p) => p.uuid === presetRoom.presetId);
  // Products don't have UUIDs, just the name
  const filtered = (products || []).filter(
    (p) => p.preset === preset?.name || p.preset === preset?.parent?.name
  );

  return filtered;
}
