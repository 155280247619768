import React from 'react';
import { ListItem, Box } from '@material-ui/core';
import styled from '@emotion/styled';

const Image = styled.img`
  width: 100%;
  height: auto;
`;

interface ProductProps {
  product: Product;
}

function imageUrl(uri: string) {
  if (uri.toLowerCase().startsWith('http')) {
    return uri;
  }
  return `https://assets.hotelfurniture.com/products/original/${uri}`;
}
const Product: React.FC<ProductProps> = ({ product }) => {
  return (
    <ListItem>
      <Box height={100} width={100}>
        {product.imagePrimary && <Image src={imageUrl(product.imagePrimary)} />}
      </Box>
      <Box pl={3}>
        {product.name} ({product.productCode})
      </Box>
    </ListItem>
  );
};

export default Product;
