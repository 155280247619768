import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from '@material-ui/core';
import styled from '@emotion/styled';
import { spacing } from '@material-ui/system';
import Explore from './Explore';
import Menu from './Menu';
import RoomListing from './room/RoomListing';
import RoomDetail from './room/RoomDetail';
import ProductListing from './product/ProductListing';
import SlotInspector from './room/SlotInspector';
import PresetListing from './preset/PresetListing';
import PresetDetail from './preset/PresetDetail';

const StyledContainer = styled(Container)`
  ${spacing}
`;

const Main: React.FC = () => {
  return (
    <StyledContainer pt={4}>
      <Router>
        <Switch>
          <Route path="/explore">
            <Explore />
          </Route>
          <Route path="/products">
            <ProductListing />
          </Route>
          <Route path="/room/:roomId/:slotId/inspect">
            <SlotInspector />
          </Route>
          <Route path="/room/:roomId">
            <RoomDetail />
          </Route>
          <Route path="/room/">
            <RoomListing />
          </Route>
          <Route path="/preset/:presetId">
            <PresetDetail />
          </Route>
          <Route path="/presetroom/:presetRoomId/:slotId/inspect">
            <SlotInspector />
          </Route>
          <Route path="/presetroom/:presetRoomId">
            <RoomDetail />
          </Route>
          <Route path="/preset/">
            <PresetListing />
          </Route>
          <Route path="/">
            <Menu />
          </Route>
        </Switch>
      </Router>
    </StyledContainer>
  );
};

export default Main;
