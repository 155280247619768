import { useMemo } from 'react';

type keyGenerator<T> = (item: T) => string;

export function groupBy<T>(
  keyName: keyof T | keyGenerator<T>,
  items: Array<T> | undefined | null
) {
  const result = new Map<string, T[]>();
  if (items) {
    items.forEach((item) => {
      let key;
      if (typeof keyName === 'function') {
        key = keyName(item);
      } else {
        key = item[keyName];
      }
      if (key) {
        const group = result.get(key);
        if (group) {
          group.push(item);
        } else {
          result.set(key, [item]);
        }
      }
    });
  }
  return result;
}

export default function useGroupBy<T>(
  keyName: keyof T | keyGenerator<T>,
  items: Array<T> | undefined | null
) {
  return useMemo(() => {
    return groupBy(keyName, items);
  }, [items, keyName]);
}
