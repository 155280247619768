import React from 'react';
import { CircularProgress } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Preset from './Preset';
import { useAppContextData } from './AppContext';

const Explore: React.FC = () => {
  const { loading, grouped } = useAppContextData();

  if (loading || !grouped) {
    return <CircularProgress />;
  }
  const items = Array.from(grouped.entries()).map(([title, rooms]) => {
    return <Preset key={title} title={title} rooms={rooms} />;
  });
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {items}
    </TreeView>
  );
};

export default React.memo(Explore);
