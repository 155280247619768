import React from 'react';
import MaterialTable from 'material-table';
import { Chip } from '@material-ui/core';
import { useAppContextData } from '../AppContext';
import { shouldFilter } from '../useFilterByTags';

const empty = [];

function productHasSlot(product: Product, slot: Slot) {
  return shouldFilter(product, slot.tagsInclude, empty, true);
}

function chipsForArray(stringArray?: string[]) {
  if (stringArray) {
    return stringArray.map((s) => <Chip label={s} key={s} />);
  }
  return null;
}

function chipsForSlots(slots?: Slot[]) {
  if (slots) {
    return slots.map((s) => <Chip label={s.name} key={s.id} />);
  }
  return null;
}

function useProductsWithSlots(defaultProducts?: Product[]): ProductWithSlot[] {
  const { products, slotsById } = useAppContextData();
  const sourceProducts = defaultProducts || products;
  if (!sourceProducts || !slotsById) {
    return empty;
  }
  const allSlots = Array.from(slotsById.values());
  return sourceProducts.map((product) => {
    const slots = allSlots.filter((s) => productHasSlot(product, s));
    return {
      ...product,
      slots,
    };
  });
}

interface ProductListingProps {
  products?: Product[];
  hideHeader?: boolean;
}

const ProductListing: React.FC<ProductListingProps> = ({
  products,
  hideHeader,
}) => {
  const productsWithSlots = useProductsWithSlots(products);

  let header;
  if (hideHeader) {
    header = !hideHeader;
  }

  return (
    <MaterialTable
      data={productsWithSlots}
      title="Products"
      columns={[
        { field: 'name' },
        { field: 'tags', render: (r) => chipsForArray(r.tags) },
        { field: 'slots', render: (r) => chipsForSlots(r.slots) },
      ]}
      options={{ paging: false, header, search: header }}
    />
  );
};

export default ProductListing;
