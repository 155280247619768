import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import {
  ListItem,
  ListItemText,
  Box,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  List,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import useFilterByTags from '../useFilterByTags';

interface SlotTypeProps {
  type: string;
  slots: RoomSlot[];
  roomProducts?: Product[];
}

interface SlotItemProps {
  slot: Slot;
  roomProducts?: Product[];
}

const empty = [];

const SlotItem: React.FC<SlotItemProps> = ({ slot, roomProducts }) => {
  const { presetRoomId } = useParams<{ presetRoomId: string }>();

  const products = useFilterByTags(
    roomProducts || empty,
    slot.tagsInclude,
    slot.tagsExclude,
    true
  );
  const history = useHistory();
  return (
    <ListItem key={slot.id}>
      <ListItemText
        primary={`${slot.name} - (${products?.length})`}
        secondary={slot.tagsInclude.join(', ')}
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => {
            history.push(`/presetroom/${presetRoomId}/${slot.id}/inspect`);
          }}
        >
          <InfoIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const SlotType: React.FC<SlotTypeProps> = ({ type, slots, roomProducts }) => {
  const slotItems = slots.map((s) => {
    if (s.slot) {
      return (
        <SlotItem key={s.slot.id} slot={s.slot} roomProducts={roomProducts} />
      );
    }
    return null;
  });
  return (
    <Box pt={2}>
      <Typography variant="h6">{type}</Typography>
      <List>{slotItems}</List>
    </Box>
  );
};

export default SlotType;
