/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Box } from '@material-ui/core';
import { useAppContextData } from '../AppContext';
import { groupBy } from '../useGroupBy';
import { shouldFilter } from '../useFilterByTags';
import SlotType from './SlotType';
import MissingProduct from './MissingProduct';
import useProductsForRoomId from './useProductsForRoomId';
import { StyledCard } from './styled';
import { useProductsForPresets } from './useProductsForPresets';

const RoomDetail: React.FC = () => {
  const { roomId, presetRoomId } = useParams<{
    roomId?: string;
    presetRoomId?: string;
  }>();

  const { presetRooms, roomCatsById, roomSlots } = useAppContextData();
  const presetDetail = (presetRooms || []).find((r) => r.id === presetRoomId);

  const filteredProducts = useProductsForPresets(presetDetail);

  const requestedRoomId = roomId || presetDetail?.roomCatId;

  const roomProducts = useProductsForRoomId(filteredProducts, requestedRoomId);

  const productWithSlots = useMemo(() => {
    if (!roomProducts || !roomSlots) {
      return [];
    }
    return roomProducts.map((p) => {
      // Find the first slot that has the product
      const matching = roomSlots.filter((rs) => {
        if (!rs.slot) {
          return false;
        }
        return shouldFilter(
          p,
          rs.slot?.tagsInclude,
          rs.slot?.tagsExclude,
          true
        );
      });
      return {
        slots: matching,
        product: p,
      };
    });
  }, [roomProducts, roomSlots]);

  const missingSlots = productWithSlots.filter((ps) => ps.slots.length === 0);
  if (!presetRooms || !roomCatsById) {
    return null;
  }

  const usedSlots = productWithSlots.filter((ps) => ps.slots.length > 0);
  if (!presetRooms || !roomCatsById) {
    return null;
  }

  const roomCategory = roomCatsById.get(requestedRoomId || 'invalid');

  if (!roomCategory || !roomSlots) {
    return (
      <Typography variant="h5">
        Hey, I can&apos;t find a room with the id {requestedRoomId}
      </Typography>
    );
  }

  const bySlotType = groupBy<RoomSlot>(
    (rs) => rs.slot?.type || '',
    roomSlots.filter((rs) => rs.roomCatId === requestedRoomId)
  );

  const slotTypes = Array.from(bySlotType.entries()).map((e, idx) => (
    <SlotType
      key={`slot-${e[0]}-${idx}`}
      type={e[0]}
      slots={e[1]}
      roomProducts={roomProducts}
    />
  ));

  const missing = missingSlots.map((sp, idx) => {
    return (
      <MissingProduct
        key={`missing-${sp.product.id}-${idx}`}
        product={sp.product}
      />
    );
  });

  const used = usedSlots.map((sp, idx) => {
    return (
      <MissingProduct
        key={`missing-${sp.product.id}-${idx}`}
        product={sp.product}
      />
    );
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box pb={4}>
          <Typography variant="h5">
            {presetDetail?.preset} - {roomCategory.name}
          </Typography>
          <Typography variant="h6">
            {roomProducts?.length} possible products for this room.{' '}
            {missingSlots.length} are not allocated
          </Typography>
          <Typography variant="subtitle2">
            Included Room Tags: {roomCategory.tagsInclude.join(', ')}
          </Typography>
          <Typography variant="subtitle2">
            Excluded Room Tags: {roomCategory.tagsExclude.join(', ')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledCard p={3}>
          <Typography variant="h5">Slots</Typography>
          {slotTypes}
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledCard p={3}>
          <Typography variant="h5">Products that need a home</Typography>
          <Typography variant="subtitle2">
            These are products that has a matching room tag but do not satisfy
            any slot tags. A product must satisfy all slot tags.
          </Typography>
          {missing}
        </StyledCard>
        <StyledCard p={3}>
          <Typography variant="h5">Products that have a home</Typography>
          {used}
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default RoomDetail;
