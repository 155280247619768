import React, { useEffect, useState, useCallback } from 'react';
import SignIn from './components/SignIn';
import AppContext from './AppContext';
import Main from './Main';

function initClient() {
  return window.gapi.client.init({
    apiKey: 'AIzaSyAA8qI1koX7aRn6UEQ73ySQWn3xfG2jVjA',
    // Your API key will be automatically added to the Discovery Document URLs.
    discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
    // clientId and scope are optional if auth is not required.
    clientId:
      '795122507941-0qm40dbfheo3t6dmumthm11tthrtjnnb.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/spreadsheets.readonly',
  });
}

function useGAPI() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    window.gapi.load('client', async () => {
      await initClient();
      setLoaded(true);
    });
  }, [setLoaded]);
  return {
    loaded,
    gapi: window.gapi,
  };
}

export const App: React.FC = () => {
  const [signedIn, setSignedIn] = useState(false);
  const { loaded } = useGAPI();
  const onSignIn = useCallback(
    (v: boolean) => {
      setSignedIn(v);
    },
    [setSignedIn]
  );

  if (!loaded) {
    return null;
  }

  return (
    <AppContext>
      <SignIn onSignIn={onSignIn} />
      {signedIn && <Main />}
    </AppContext>
  );
};

export default App;
